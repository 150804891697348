import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
//import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
//import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  //const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="lDAdtTy75w8" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <p>
        2005年以来、中村研究室が発表してきた動く分子の映像は、世界中から大きな注目を集めてきた。そして、革新分子技術総括寄付講座が2015年に設立される。分子を適切な手法で設計・合成・制御・集積し、望みの機能をもつ分子を創り出し、応用へ繋げていく技術である。この分子技術が、新材料・新デバイス・新プロセス・新分析手法などを生み出す「分子の自在な制御」を可能にし、イノベーションを創出する。<br />
        研究室を率いるのは中村栄一教授。彼の下で多くの国際性に富む若いメンバーが研究に取り組んでいる。本作は中村研究室の活動を紹介する映像である。
      </p>
    );
  };

  const summaryData = {
    scopes: ['Produce', 'Direction', 'Shooting', 'Video Production'],
    awards: [],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      {/*
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(0, 1)} column={1} />
      </WorkDetailImageGallery>
      */}
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/nakamura_molecule"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/nakamura_molecule.*"}) {
      publicURL
    }
  }
`;
